import React from "react";
import { createLink, Outlet } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
// mantine
import { AppShell, Burger, Group, Space, Anchor, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
// UI
import { MightierLogoSVG } from "@components/MightierLogoSvg";

import cssClasses from "./members_layout.module.css";
import { IconExternalLink } from "@tabler/icons-react";
import { staticLinks } from "@config/staticLinks";
import { NavBarItem } from "@components/PageContainer/NavBarItem";

import { backendApi } from "src/backendApi/backendApi";

const NavBarLink = createLink(Anchor);

export const MembersLayout = React.memo(() => {
  const [opened, { toggle, close }] = useDisclosure();
  const { t } = useTranslation();

  const openModal = () =>
    modals.openConfirmModal({
      title: t("membersLayout.logOutModalTitle"),
      children: <Text>{t("membersLayout.logOutModalMessage")}</Text>,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => {
        await backendApi.logout();
      },
    });

  return (
    <AppShell
      header={{ height: 83 }}
      navbar={{ width: 300, breakpoint: "sm", collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header className={cssClasses.header}>
        <Group h="100%" px="lg" justify="space-between">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <NavBarLink to="/" style={{ cursor: "pointer" }}>
            <MightierLogoSVG />
          </NavBarLink>
          <Anchor onClick={openModal}>{t("membersLayout.logOut")}</Anchor>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="xl" bg="#012B60">
        <Space h={120} />

        <NavBarLink to="/members/enroll" mb="xl" onClick={close}>
          {({ isActive }) => (
            <NavBarItem isActive={isActive}>
              {t("membersLayout.navBar.createAccount")}
            </NavBarItem>
          )}
        </NavBarLink>

        <NavBarLink to="/members/search" mb="xl" onClick={close}>
          {({ isActive }) => (
            <NavBarItem isActive={isActive}>
              {t("membersLayout.navBar.manageExisting")}
            </NavBarItem>
          )}
        </NavBarLink>

        <Anchor
          href={staticLinks.howItWorks}
          target="_blank"
          rel="noopener noreferrer"
          mb="xl"
          onClick={close}
        >
          <NavBarItem withChevron={false}>
            {t("membersLayout.navBar.howMightierWorks")}
            <IconExternalLink />
          </NavBarItem>
        </Anchor>

        <Anchor
          href={staticLinks.faqs}
          target="_blank"
          rel="noopener noreferrer"
          mb="xl"
          onClick={close}
        >
          <NavBarItem withChevron={false}>
            {t("membersLayout.navBar.faqs")}
            <IconExternalLink />
          </NavBarItem>
        </Anchor>

        <Anchor
          href={staticLinks.contactPage}
          target="_blank"
          rel="noopener noreferrer"
          mb="xl"
          onClick={close}
        >
          <NavBarItem withChevron={false}>
            {t("membersLayout.navBar.contactMightier")}
            <IconExternalLink />
          </NavBarItem>
        </Anchor>
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
});

MembersLayout.displayName = "MembersLayout";
