import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Center, BackgroundImage } from "@mantine/core";

import backgroundImg from "../../assets/images/start_background.png";

import classes from "./start_page.module.css";
import { useColors } from "@utils/useColors";

export const StartIndexPage = React.memo(function StartIndexPage() {
  const { t } = useTranslation();
  const colors = useColors();

  return (
    <BackgroundImage
      src={backgroundImg}
      className={classes["background-image"]}
    >
      <Center className={classes["header-container"]}>
        <h1>{t("startPageHeader")}</h1>
      </Center>

      <Center className={classes["set-password-container"]}>
        <Box className={classes["set-password-card"]} bg={colors.beige}>
          <Box className={classes["set-passord-content"]}>
            <p>{t("startPageBody")}</p>
          </Box>
        </Box>
      </Center>
    </BackgroundImage>
  );
});
