import {
  Outlet,
  createRootRouteWithContext,
  redirect,
} from "@tanstack/react-router";
import { FourOhFourPage } from "@pages/fourOhFourPage";
import { backendApi } from "../backendApi/backendApi";
import { RouterContext } from "@config/tanstack";

export const Route = createRootRouteWithContext<RouterContext>()({
  component: Outlet,
  notFoundComponent: FourOhFourPage,
  beforeLoad: async ({ location, context: { auth } }) => {
    if (location.pathname !== "/login") {
      if (!(await auth.verifyAuth())) {
        throw redirect({
          to: "/login",
          search: {
            // Use the current location to power a redirect after login
            // (Do not use `router.state.resolvedLocation` as it can
            // potentially lag behind the actual current location)
            // redirect: location.href,
          },
        });
      }
    } else if (location.pathname === "/login" && backendApi.checkTokens()) {
      throw redirect({
        to: "/",
      });
    }
  },
});
