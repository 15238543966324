import { PasswordInput, PinInput, rem, TextInput } from "@mantine/core";

const loginInputVars = { "--input-height-md": rem(48) };

export const LoginTextInput = TextInput.withProps({
  styles: { wrapper: loginInputVars },
  radius: "10px",
  size: "md",
});

export const LoginPasswordInput = PasswordInput.withProps({
  styles: { wrapper: loginInputVars },
  radius: "10px",
  size: "md",
});

export const LoginPinInput = PinInput.withProps({
  radius: "10px",
  length: 6,
  type: /^[0-9]*$/,
  inputType: "number",
  styles: {
    pinInput: {
      height: rem(48),
      width: rem(68),
    },
    input: {
      height: rem(48),
      width: rem(68),
    },
  },
});

export const StandardTextInput = TextInput.withProps({
  size: "md",
  labelProps: { fz: "12px", mb: rem(4) },
  errorProps: { fz: "12px" },
  styles: {
    required: { color: "black" },
  },
});
