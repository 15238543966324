import { PageContainer } from "@components/PageContainer";
import { Title3 } from "@components/Typography";
import { rem, Box, Divider } from "@mantine/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MemberLookupForm } from "./MemberLookupForm";
import { MemberNotFound } from "./MemberNotFound";
import { AnimatedHeightWrapper } from "@components/AnimatedHeightWrapper";
import { getRouteApi } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { MemberInfo } from "./MemberInfo";
import { backendApi } from "src/backendApi/backendApi";

const routeApi = getRouteApi("/members/search");

export const MemberLookupPage: FC = () => {
  const { t } = useTranslation();

  const search = routeApi.useSearch();
  const isSearchEnabled = Boolean(search.caseNum || search.email);

  const {
    data: user,
    isFetching,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: ["MEMBER_FIND", search],
    queryFn: async ({ queryKey }) => {
      const search = queryKey[1];
      if (typeof search === "string" || search == null)
        throw new Error("Invalid query key");

      const result = (
        await backendApi.getUsers({
          ...(search.email && { email: search.email }),
          ...(search.caseNum && {
            enterprise_eligibility_states__external_id: search.caseNum,
          }),
        })
      ).data;

      const foundUser = result.results[0];
      return foundUser ?? null;
    },
    enabled: isSearchEnabled,
    retry: false,
  });

  return (
    <PageContainer>
      <Box maw={rem(550)}>
        <Box maw={rem(450)}>
          <Title3>{t("memberLookupPage.memberLookup")}</Title3>

          <MemberLookupForm
            isFetching={isFetching}
            refetch={async () => {
              await refetch();
            }}
          />

          {isFetched && user && <Divider mb="xl" mt="xl" />}
        </Box>

        <AnimatedHeightWrapper>
          {isFetched && user && <MemberInfo user={user} />}

          {isFetched && user === null && <MemberNotFound />}
        </AnimatedHeightWrapper>
      </Box>
    </PageContainer>
  );
};
