import React from "react";
import { useTranslation } from "react-i18next";

import { Box, rem, Stack, Title } from "@mantine/core";

import { MightierLogoSVG } from "@components/MightierLogoSvg";
import { LoginForm } from "./LoginForm";

export const LoginPage = React.memo(function LoginPage() {
  const { t } = useTranslation();

  return (
    <Box pt={rem(140)} px={rem(100)} pb={rem(30)}>
      <Stack mb={15} gap={12}>
        <MightierLogoSVG height={19} />
        <Title>{t("loginPage.careGuideLogin")}</Title>
      </Stack>
      <LoginForm />
    </Box>
  );
});
