import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// NOTE: to implement lazy-loading in the future, i think you'll want
// to move these into the `/public` dir!!
// lazy-loading them in the future. They'll be in the public dir then!
import translationEn from "../../assets/locales/en/translation.json";

// NOTE: only english for now!
// import translationAr from "../assets/locales/ar/translation.json";
// import translationEs from "../assets/locales/es/translation.json";
// import translationFrCA from "../assets/locales/fr-CA/translation.json";
// import translationRu from "../assets/locales/ru/translation.json";
// import translationUk from "../assets/locales/uk/translation.json";
// import translationVi from "../assets/locales/vi/translation.json";
// import translationZhCN from "../assets/locales/zh-CN/translation.json";

// types
export type TranslationConfig = typeof translationEn;

export const DEFAULT_LANGUAGE = "en";

export type SupportedLanguage = "en";
// NOTE: only english for now!
// | "es"
// | "ar"
// | "ru"
// | "uk"
// | "vi"
// | "zh-CN"
// | "fr-CA";
// NOTE: if any JSON file doesn't adhear to type of english translation config interface, there's a TS error
type I18nResources = Record<
  SupportedLanguage,
  { translation: TranslationConfig }
>;

export const resources: I18nResources = {
  en: { translation: translationEn },
  // NOTE: we do not have others yet
  // es: { translation: translationEs },
  // ar: { translation: translationAr },
  // ru: { translation: translationRu },
  // uk: { translation: translationUk },
  // vi: { translation: translationVi },
  // "zh-CN": { translation: translationZhCN },
  // "fr-CA": { translation: translationFrCA },
} as const;

export const SUPPORTED_LANGUAGES = Object.keys(
  resources,
) as SupportedLanguage[];

let isInittted = false;

(function initI18n(): void {
  if (isInittted) {
    return;
  }

  i18n
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources,
      // alert us if the key is missing! `saveMissing` is misleading. We don't save anything to the translations files/backend/etc.
      // like what missingKeyHandler is intended for. But `saveMissing` must be set to `true` for this handler func to get called
      saveMissing: true,
      missingKeyHandler: (lngs, namespace, key, res) => {
        // TODO: sentry
        // Sentry.captureMessage("Missing translation key", { extra: { key } });
      },
      fallbackLng: DEFAULT_LANGUAGE,
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      supportedLngs: SUPPORTED_LANGUAGES,
    });

  isInittted = true;
})();

// eslint-disable-next-line import/no-default-export
export default i18n;
export * from "./I18nextSetup";
