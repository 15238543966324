import { UseFormReturnType } from "@mantine/form";

export const steps = {
  email: "email",
  password: "password",
  code: "code",
};

export type Step = keyof typeof steps;

export const initialValues = {
  email: "",
  password: "",
  code: "",
  remember: false,
};

export type LoginFormType = UseFormReturnType<typeof initialValues>;
