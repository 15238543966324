import { Text16Medium } from "@components/Typography";
import { staticLinks } from "@config/staticLinks";
import { Anchor } from "@mantine/core";
import { useColors } from "@utils/useColors";
import React from "react";
import { useTranslation } from "react-i18next";

export const MemberNotFound = () => {
  const { t } = useTranslation();
  const { errorRed100 } = useColors();
  return (
    <Text16Medium c={errorRed100} mt="xl">
      {t("memberInfo.memberNotFound")}{" "}
      <Anchor
        href={staticLinks.contactPage}
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontSize: "inherit", fontWeight: "inherit" }}
      >
        {t("membersLayout.navBar.contactMightier")}
      </Anchor>{" "}
      {t("memberInfo.forFurtherAssistance")} <br />
      <br />
      {t("memberInfo.supportPhoneNumber")}
    </Text16Medium>
  );
};
