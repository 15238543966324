import { AnimatedHeightWrapper } from "@components/AnimatedHeightWrapper";
import { LoginTextInput } from "@components/Inputs";
import { router } from "@config/tanstack";
import { Button, rem, Stack, Text, Alert } from "@mantine/core";
import { useForm } from "@mantine/form";
import { getRouteApi } from "@tanstack/react-router";
import { t } from "i18next";
import React, { FC, useEffect } from "react";
import { IconInfoCircle } from "@tabler/icons-react";

type Props = {
  isFetching?: boolean;
  refetch: () => Promise<void>;
};

const initialValues = {
  email: "",
  caseNum: "",
};

const routeApi = getRouteApi("/members/search");

export const MemberLookupForm: FC<Props> = ({ isFetching, refetch }) => {
  const search = routeApi.useSearch();
  const icon = <IconInfoCircle />;

  const form = useForm({
    initialValues: {
      ...initialValues,
      ...search,
    },
    validate: (values) => {
      const errors: Partial<Record<keyof typeof initialValues, string>> = {};

      if (!values.caseNum && !values.email) {
        errors.caseNum = "At least one field should be filled";
        errors.email = "At least one field should be filled";
      }

      return errors;
    },
  });

  useEffect(() => {
    form.setInitialValues({
      ...initialValues,
      ...search,
    });
    form.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(search)]);

  const handleSubmit = async (values: (typeof form)["values"]) => {
    refetch();
    if (!values.caseNum && !values.email) return;

    const search = {
      ...(values.email && { email: values.email.trim() }),
      ...(values.caseNum && { caseNum: values.caseNum.trim() }),
    };

    router.navigate({ search });
  };

  return (
    <form
      noValidate
      onSubmit={form.onSubmit(handleSubmit)}
      key={JSON.stringify(search)}
    >
      <Stack gap={rem(12)}>
        <AnimatedHeightWrapper>
          <LoginTextInput
            key={search.email}
            label={t("memberLookupPage.searchByEmail")}
            {...form.getInputProps("email")}
            disabled={isFetching}
          />
        </AnimatedHeightWrapper>
        <Text fw={500}>{t("memberLookupPage.or")}</Text>

        <AnimatedHeightWrapper>
          <LoginTextInput
            key={search.caseNum}
            label={t("memberLookupPage.searchByCaseNumber")}
            {...form.getInputProps("caseNum")}
            disabled={isFetching}
          />
        </AnimatedHeightWrapper>
      </Stack>

      <Button type="submit" mt={rem(25)} loading={isFetching}>
        {t("memberLookupPage.submit")}
      </Button>
      <Alert mt={rem(5)} variant="light" color="pink" icon={icon}>
        <Text size="sm">{t("memberLookupPage.delayMessage")}</Text>
      </Alert>
    </form>
  );
};
