import React, { FC, useState, useCallback } from "react";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
// style
import { rem, Stack } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { isEmail, useForm, isNotEmpty, hasLength } from "@mantine/form";
// steps
import { EmailStep } from "./StepEmail";
import { PasswordStep } from "./StepPassword";
import { OTPStep } from "./StepOTP";
// form
import { initialValues, type Step, steps } from "./formConfig";
// api
import { backendApi } from "src/backendApi/backendApi";
import { useMutation } from "@tanstack/react-query";

const MIN_SECONDS_BETWEEN_REQUESTS = 30;

export const LoginForm: FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = React.useState<Step>("email");
  const { t } = useTranslation();

  const [mfaTempSecret, setMfaTempSecret] = useState("");
  const [otpRequestedTime, setOtpRequestedTime] = useState(0);

  const errors = {
    invalidEmail: t("loginPage.validation.invalidEmail"),
    passwordRequired: t("loginPage.validation.passwordRequired"),
    codeLength: t("loginPage.validation.codeLength"),
  };

  const form = useForm({
    initialValues,
    validate: {
      email: isEmail(errors.invalidEmail),
      password: isNotEmpty(errors.passwordRequired),
      code: hasLength({ min: 6, max: 6 }, errors.codeLength),
    },
  });

  const showErr = useCallback(
    ({ title, message }: { title?: string; message: string }) => {
      notifications.show({ title, message, color: "red", withBorder: true });
    },
    [],
  );

  const obtainJwtPairMutation = useMutation({
    mutationKey: ["obtainJwtPair"],
    mutationFn: async ({
      mfaTempSecret,
      code,
      remember,
    }: {
      mfaTempSecret: string;
      code: string;
      remember: boolean;
    }) => {
      return await backendApi.jwtObtainPair(mfaTempSecret, code, remember);
    },
    onSuccess: () => {
      navigate({ to: "/members/enroll" });
    },
    onError: (e) => {
      console.error(e);
      showErr({
        title: t("loginPage.3_errTitle"),
        message: t("loginPage.3_errMessage"),
      });
    },
  });

  const handleSubmit = form.onSubmit(async (values) =>
    obtainJwtPairMutation.mutate({ ...values, mfaTempSecret }),
  );

  const handleNextForEmailStep = async (): Promise<void> => {
    const { hasError } = form.validateField("email");
    if (!hasError) {
      setStep("password");
    }
  };

  const otpMutation = useMutation({
    mutationKey: ["requestOtp"],
    mutationFn: async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      const millisecondsPassed = Date.now() - otpRequestedTime;
      if (millisecondsPassed < MIN_SECONDS_BETWEEN_REQUESTS * 1000) {
        throw new Error("You can only request a code every 60 seconds", {
          cause: "rate_limit",
        });
      }

      return backendApi.jwtMfaRequestOtp(email, password);
    },

    onSuccess: ({ data }) => {
      setOtpRequestedTime(Date.now());
      setMfaTempSecret(data.secret);
      setStep("code");
    },
    onError: (e) => {
      if (e?.cause === "rate_limit") {
        showErr({
          message: t("loginPage.2_requestOTPTooOftenErrMessage", {
            MIN_SECONDS_BETWEEN_REQUESTS,
          }),
        });
        return;
      }

      console.error(e);
      showErr({
        title: t("loginPage.2_errTitle"),
        message: t("loginPage.2_errMessage"),
      });
    },
  });

  const handleValidatonAndSendCode = async (): Promise<void> => {
    const { hasError } = form.validateField("password");
    if (!hasError) {
      otpMutation
        .mutateAsync({
          email: form.values.email,
          password: form.values.password,
        })
        .then(() => {
          notifications.show({
            title: t("loginPage.3_codeResentTitle"),
            message: t("loginPage.3_codeResentMessage"),
            withBorder: true,
          });
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack maw={470} gap={rem(27)} align="start">
        {step === steps.email && (
          <EmailStep form={form} handleNext={handleNextForEmailStep} />
        )}
        {step === steps.password && (
          <PasswordStep
            form={form}
            handleNext={handleValidatonAndSendCode}
            isPending={otpMutation.isPending}
          />
        )}
        {step === steps.code && (
          <OTPStep
            form={form}
            onResendCodePressed={handleValidatonAndSendCode}
            isResendCodePending={otpMutation.isPending}
            isFormSubmitPending={obtainJwtPairMutation.isPending}
          />
        )}
      </Stack>
    </form>
  );
};
