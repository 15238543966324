import { Title3 } from "@components/Typography";
import { Box, Button, rem } from "@mantine/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  caseNumber: string;
  onStartAgain: () => void;
};

export const ShowCaseNumber: FC<Props> = ({ onStartAgain, caseNumber }) => {
  const { t } = useTranslation();
  return (
    <Box data-testid="showcase-num-box">
      <Title3 mb={rem(30)}>{t("memberCreatePage.thankYou")}</Title3>
      <Title3 mb={rem(30)} data-testid="showcase-num-casenumber">
        {t("memberCreatePage.caseN")} {caseNumber}
      </Title3>

      <Button onClick={onStartAgain}>
        {t("memberCreatePage.createAnotherAccount")}
      </Button>
    </Box>
  );
};
