import { AnimatedHeightWrapper } from "@components/AnimatedHeightWrapper";
import { LoginPasswordInput } from "@components/Inputs";
import { Stack, Button, Text } from "@mantine/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { LoginFormType } from "./formConfig";

export const PasswordStep: FC<{
  form: LoginFormType;
  handleNext(): void;
  isPending: boolean;
}> = ({ form, handleNext, isPending: pending }) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack gap={6} style={{ alignSelf: "stretch" }}>
        <Text>{t("loginPage.2_enterPasssword")}</Text>
        <AnimatedHeightWrapper>
          <LoginPasswordInput
            {...form.getInputProps("password")}
            placeholder={t("loginPage.2_password")}
          />
        </AnimatedHeightWrapper>
      </Stack>

      <Button onClick={handleNext} loading={pending}>
        {t("loginPage.next")}
      </Button>

      <Text maw="max(70%, 350px)">{t("loginPage.2_havingTrouble")}</Text>
    </>
  );
};
