import React, { FC } from "react";
import { PageContainer } from "@components/PageContainer";
import { Text12Medium, Title3, Title5 } from "@components/Typography";
import { rem, Box } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ContactInfoForm } from "./ContactInfoForm";
import { ShowCaseNumber } from "./ShowCaseNumber";

export const MemberCreatePage: FC = () => {
  const { t } = useTranslation();

  const [caseNumber, setCaseNumber] = React.useState<string | null>(null);

  const handleSubmitted = (caseNumber: string) => {
    setCaseNumber(caseNumber);
  };

  const handleReset = () => setCaseNumber(null);

  return (
    <PageContainer>
      <Box maw={rem(450)}>
        {!caseNumber && (
          <>
            <Title3 mb={rem(6)}>{t("memberCreatePage.header")}</Title3>

            <Text12Medium mb={rem(15)}>
              {t("memberCreatePage.uponAccountCreation")}
            </Text12Medium>

            <Title5 mb={rem(19)}>
              {t("memberCreatePage.memberContactInformation")}
            </Title5>

            <ContactInfoForm onSubmitted={handleSubmitted} />
          </>
        )}

        {caseNumber && (
          <ShowCaseNumber caseNumber={caseNumber} onStartAgain={handleReset} />
        )}
      </Box>
    </PageContainer>
  );
};
