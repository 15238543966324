import { AnimatedHeightWrapper } from "@components/AnimatedHeightWrapper";
import { LoginTextInput } from "@components/Inputs";
import { Stack, Button, Text } from "@mantine/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { LoginFormType } from "./formConfig";

export const EmailStep: FC<{ form: LoginFormType; handleNext(): void }> = ({
  form,
  handleNext,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack gap={6} style={{ alignSelf: "stretch" }}>
        <Text>{t("loginPage.1_enterEmail")}</Text>
        <AnimatedHeightWrapper>
          <LoginTextInput
            {...form.getInputProps("email")}
            placeholder={t("loginPage.1_email")}
          />
        </AnimatedHeightWrapper>
      </Stack>

      <Button onClick={handleNext} disabled={!!form.errors.email}>
        {t("loginPage.next")}
      </Button>
    </>
  );
};
