import { MemberLookupPage } from "@pages/memberLookup";
import { createFileRoute } from "@tanstack/react-router";

export type MemberSearch = {
  email?: string;
  caseNum?: number;
};

export const Route = createFileRoute("/members/search")({
  component: MemberLookupPage,
  validateSearch: (search: Record<string, unknown>) => {
    const email = String(search.email ?? "");
    const caseNum = String(search.caseNum ?? "");

    return {
      ...(email && { email }),
      ...(caseNum && { caseNum }),
    };
  },
});
