import React from "react";
import ReactDOM from "react-dom/client";

import "@fontsource/poppins";

import { MantineSetup } from "./config/mantine/mantine";
import { TanstackQuerySetup, TanstackRouterSetup } from "./config/tanstack";
import { I18nextSetup } from "./config/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <MantineSetup>
      <I18nextSetup>
        <TanstackQuerySetup>
          <TanstackRouterSetup />
        </TanstackQuerySetup>
      </I18nextSetup>
    </MantineSetup>
  </React.StrictMode>,
);
