const colors = {
  gray20: "#D6D6D6",
  tufts150: "#0174DF",
  beige: "#FBF3D8",
  royal100: "#012B60",
  spearmint100: "#21CE9D",
  errorRed100: "#D2423A",
} as const;

export const mightierColors = { ...colors, primary: colors.tufts150 };

export type MightierColors = typeof mightierColors;
