import { AnimatedHeightWrapper } from "@components/AnimatedHeightWrapper";
import {
  Stack,
  Button,
  Text,
  Anchor,
  Checkbox,
  InputError,
  Loader,
  Flex,
} from "@mantine/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { LoginPinInput } from "@components/Inputs";
import { LoginFormType } from "./formConfig";

export const OTPStep: FC<{
  form: LoginFormType;
  onResendCodePressed?: () => unknown;
  isResendCodePending: boolean;
  isFormSubmitPending: boolean;
}> = ({
  form,
  onResendCodePressed,
  isResendCodePending,
  isFormSubmitPending,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack gap={6} style={{ alignSelf: "stretch" }}>
        <Text>{t("loginPage.3_enterCode")}</Text>
        <AnimatedHeightWrapper>
          <LoginPinInput {...form.getInputProps("code")} mb={5} />
          {form.errors.code && (
            <InputError size="md">{form.errors.code}</InputError>
          )}
        </AnimatedHeightWrapper>
      </Stack>

      <Checkbox
        {...form.getInputProps("remember")}
        label={t("loginPage.3_rememberDevice")}
        size="md"
      />

      <Flex align="center" gap="xs">
        <Anchor
          component="button"
          type="button"
          fw={500}
          onClick={onResendCodePressed}
          disabled={isResendCodePending}
        >
          {t("loginPage.3_resendCode")}
        </Anchor>
        {isResendCodePending && <Loader size="xs" />}
      </Flex>

      <Button type="submit" loading={isFormSubmitPending}>
        {t("loginPage.login")}
      </Button>
    </>
  );
};
