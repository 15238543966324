import React, { FC, PropsWithChildren } from "react";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";

import {
  createTheme,
  MantineProvider,
  DEFAULT_THEME,
  mergeMantineTheme,
  Button,
  rem,
  Checkbox,
  CSSVariablesResolver,
  Anchor,
} from "@mantine/core";

// core styles are required for all packages
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import { mightierColors } from "@config/colors/colors";

// prettier-ignore
type MantineColorTuple = readonly [string,string,string,string,string,string,string,string,string,string];

const createMantineColor = (color: string): MantineColorTuple => {
  return Array(10).fill(color) as unknown as MantineColorTuple;
};

const themeOverrides = createTheme({
  primaryColor: "mightierPrimary",
  other: {
    colors: mightierColors,
  },
  fontFamily: "Poppins",
  colors: {
    mightierPrimary: createMantineColor(mightierColors.primary),
  },
  components: {
    Anchor: Anchor.extend({
      defaultProps: {
        underline: "never",
      },
    }),
    Button: Button.extend({
      defaultProps: {
        radius: 99999,
        size: "md",
        loaderProps: { size: "sm" },
      },
      vars: (_theme, props) => {
        if (props.size === "md") {
          return {
            root: {
              "--button-height": rem(56),
              "--button-padding-x": rem(32),
              "--button-fz": rem(16),
            },
          };
        }

        return { root: {} };
      },
      styles(theme, props, ctx) {
        if (props.variant === "outline") {
          return {
            root: {
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            },
          };
        }
        return {};
      },
    }),
    Checkbox: Checkbox.extend({
      defaultProps: { radius: "sm" },
      vars: (_theme, props) => {
        if (props.size === "md")
          return { root: { "--checkbox-size": rem(22) } };
        return { root: {} };
      },
    }),
  },
  defaultRadius: "7px",
  headings: {
    sizes: {
      h1: {
        fontSize: "40px",
      },
      h3: {
        fontSize: "20px",
      },
    },
  },
});

const resolver: CSSVariablesResolver = () => ({
  variables: {
    // override Mantine's default theme variables if needed
  },
  dark: {},
  light: {},
});

const theme = mergeMantineTheme(DEFAULT_THEME, themeOverrides);

export const MantineSetup: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme} cssVariablesResolver={resolver}>
      <Notifications position="top-right" />
      <ModalsProvider>{children}</ModalsProvider>
    </MantineProvider>
  );
};
