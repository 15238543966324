import type { MightierSupportedLanguage } from "@typings/mightierApiTypes";

export const languageOptions: {
  value: MightierSupportedLanguage;
  label: string;
}[] = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
  { value: "ar", label: "Arabic" },
  { value: "ru", label: "Russian" },
  { value: "uk", label: "Ukrainian" },
  { value: "vi", label: "Vietnamese" },
  { value: "zh-CN", label: "Chinese (Simplified)" },
  { value: "fr-CA", label: "French (Canada)" },
];

export const DEFAULT_LANGUAGE_OPTION = "en";
