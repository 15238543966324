import React from "react";
import { useTranslation } from "react-i18next";

export const FourOhFourPage = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("fourOhFourPage.header")}</h1>
    </div>
  );
});

FourOhFourPage.displayName = "FourOhFourPage";
