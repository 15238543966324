import React, { FC, PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const TanStackQueryDevtools =
  process.env.NODE_ENV === "production"
    ? (): null => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/react-query-devtools").then((res) => ({
          default: res.ReactQueryDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // default - queries will be refetched if same query key is mounted again (by same or another component)
      // after 60s
      staleTime: 60 * 1000,
    },
  },
});

export const TanstackQuerySetup: FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <TanStackQueryDevtools />
    </QueryClientProvider>
  );
};
