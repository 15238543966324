import { Box } from "@mantine/core";
import type { ReactNode } from "react";
import React, { useEffect, useRef, useState } from "react";

export const AnimatedHeightWrapper = ({
  children,
  collapsedInitially = false,
}: {
  children: ReactNode;
  collapsedInitially?: boolean;
}): ReactNode => {
  const heightMeasureRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<`${number}px`>("0px");

  // skipped by default. set collapsedInitially to true to show initial transition
  const isSkippedRef = useRef(!collapsedInitially);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setHeight(`${entry.target.scrollHeight}px`);
        isSkippedRef.current = false;
      }
    });

    if (heightMeasureRef.current) {
      resizeObserver.observe(heightMeasureRef.current);
    }

    return (): void => {
      if (heightMeasureRef.current) {
        resizeObserver.unobserve(heightMeasureRef.current);
      }
    };
  }, [children]);

  return (
    <Box
      style={{
        overflow: "hidden",
        transition: "max-height .25s ease, min-height .25s ease",
        ...(!isSkippedRef.current && { maxHeight: height, minHeight: height }),
      }}
    >
      <div ref={heightMeasureRef}>{children}</div>
    </Box>
  );
};
