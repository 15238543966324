import React, { PropsWithChildren } from "react";
// mantine
import { Text, Flex, rem } from "@mantine/core";
// UI

import { IconChevronRight } from "@tabler/icons-react";
import { useColors } from "@utils/useColors";

export const NavBarItem = React.memo<
  PropsWithChildren<{
    isActive?: boolean;
    withChevron?: boolean;
  }>
>(function NavBarItem({ isActive, withChevron = true, children }) {
  const colors = useColors();

  return (
    <Flex
      wrap="nowrap"
      justify="space-between"
      align="center"
      style={{
        color: "#fff",
        ...(isActive && { color: colors.spearmint100 }),
      }}
    >
      <Text
        fz="md"
        fw="600"
        display="inline-flex"
        style={{ display: "inline-flex", alignItems: "center", gap: "16px" }}
      >
        {children}
      </Text>
      {withChevron && (
        <IconChevronRight
          style={{ height: rem(25), flexShrink: 0, marginRight: -7 }}
        />
      )}
    </Flex>
  );
});
