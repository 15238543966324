import { AnimatedHeightWrapper } from "@components/AnimatedHeightWrapper";
import { StandardTextInput } from "@components/Inputs";
import { Grid, rem, Select, Button } from "@mantine/core";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { IconChevronDown } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useColors as useMightierColors } from "@utils/useColors";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE_OPTION, languageOptions } from "./languageOptions";

import PhoneInput, { parsePhoneNumber } from "react-phone-number-input/input";

import { notifications } from "@mantine/notifications";
import { backendApi } from "src/backendApi/backendApi";
import type { MightierSupportedLanguage } from "@typings/mightierApiTypes";
import type { GreenshieldCreateMightierAccountPayload } from "@typings/enterpriseServerlessTypes";

/**
 * GS is canada-only for now. Assuming that all phone numbers are canada phone numbers
 * might not be 100% correct, but it's a safe bet and
 */
const PHONE_NUM_COUNTRY_CODE = "CA";

const isPhoneNumberValid = (errorMessage: string) => (value: string) => {
  try {
    const phoneNum = parsePhoneNumber(value, PHONE_NUM_COUNTRY_CODE);
    const isValid = phoneNum?.isValid();
    return isValid ? null : errorMessage;
  } catch (e) {
    return errorMessage;
  }
};

type MemberInfoInputData = {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  apartment?: string;
  city: string;
  province: string;
  postalCode: string;
  phoneNumber: string;
  preferredLanguage: string;
};

type ContactInfoFormProps = {
  onSubmitted: (caseNumber: string) => void;
};

export const ContactInfoForm: FC<ContactInfoFormProps> = ({ onSubmitted }) => {
  const { t } = useTranslation();
  const colors = useMightierColors();

  const mutationFn = React.useCallback(
    async (memberInfo: MemberInfoInputData) => {
      const reqBody: GreenshieldCreateMightierAccountPayload = {
        first_name: memberInfo.firstName,
        last_name: memberInfo.lastName,
        email: memberInfo.email,
        address: memberInfo.address,
        address2: memberInfo.apartment || "",
        city: memberInfo.city,
        state: memberInfo.province,
        zip: memberInfo.postalCode,
        phone: memberInfo.phoneNumber,
        preferred_language:
          memberInfo.preferredLanguage as MightierSupportedLanguage, // Ensure to validate or map this to a supported language
      };

      const resp = await backendApi.createGreenshieldMightierAccount(reqBody);

      return {
        caseNumber: resp.data.caseNumber,
      };
    },
    [],
  );

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["createMemberAccount"],
    mutationFn,
    onSuccess: ({ caseNumber }) => {},
    onError: (e) => {
      const title = t("memberCreatePage.errNotificationTitle");
      const message = t("memberCreatePage.errNotificationMessage");

      notifications.show({
        title,
        message,
        color: "red",
        withBorder: true,
      });
    },
  });

  const form = useForm<MemberInfoInputData>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      apartment: "",
      city: "",
      province: "",
      postalCode: "",
      phoneNumber: "",
      preferredLanguage: DEFAULT_LANGUAGE_OPTION,
    },
    validate: {
      firstName: isNotEmpty("This field is required"),
      lastName: isNotEmpty("This field is required"),
      email: isEmail("Invalid email"),
      address: isNotEmpty("This field is required"),
      apartment: undefined,
      city: isNotEmpty("This field is required"),
      province: isNotEmpty("This field is required"),
      postalCode: isNotEmpty("This field is required"),
      phoneNumber: (value) =>
        isNotEmpty("This field is required")(value) ||
        isPhoneNumberValid("Please enter a valid phone number")(value),
      preferredLanguage: isNotEmpty("This field is required"),
    },
  });

  const handleSubmit = async (values: (typeof form)["values"]) => {
    console.log(values);
    const { caseNumber } = await mutateAsync(values);
    onSubmitted(String(caseNumber));
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Grid gutter={rem(14)} styles={{ col: { paddingBottom: rem(12) } }}>
        <Grid.Col span={{ base: 12, xs: 6 }}>
          <AnimatedHeightWrapper>
            <StandardTextInput
              label={t("memberCreatePage.fields.firstName")}
              {...form.getInputProps("firstName")}
              required
              disabled={isPending}
              data-testid="member-create-firstname"
            />
          </AnimatedHeightWrapper>
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 6 }}>
          <AnimatedHeightWrapper>
            <StandardTextInput
              label={t("memberCreatePage.fields.lastName")}
              {...form.getInputProps("lastName")}
              required
              disabled={isPending}
              data-testid="member-create-lastname"
            />
          </AnimatedHeightWrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <AnimatedHeightWrapper>
            <StandardTextInput
              label={t("memberCreatePage.fields.emailAddress")}
              {...form.getInputProps("email")}
              required
              disabled={isPending}
              data-testid="member-create-email"
            />
          </AnimatedHeightWrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <AnimatedHeightWrapper>
            <StandardTextInput
              label={t("memberCreatePage.fields.address")}
              {...form.getInputProps("address")}
              required
              disabled={isPending}
              data-testid="member-create-address1"
            />
          </AnimatedHeightWrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <AnimatedHeightWrapper>
            <StandardTextInput
              label={t("memberCreatePage.fields.apartmentSuiteOptional")}
              {...form.getInputProps("apartment")}
              disabled={isPending}
              data-testid="member-create-address2"
            />
          </AnimatedHeightWrapper>
        </Grid.Col>

        <Grid.Col span={12}>
          <AnimatedHeightWrapper>
            <StandardTextInput
              label={t("memberCreatePage.fields.city")}
              {...form.getInputProps("city")}
              required
              disabled={isPending}
              data-testid="member-create-city"
            />
          </AnimatedHeightWrapper>
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 6 }}>
          <AnimatedHeightWrapper>
            <StandardTextInput
              label={t("memberCreatePage.fields.province")}
              {...form.getInputProps("province")}
              required
              disabled={isPending}
              data-testid="member-create-province"
            />
          </AnimatedHeightWrapper>
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 6 }}>
          <AnimatedHeightWrapper>
            <StandardTextInput
              label={t("memberCreatePage.fields.postalCode")}
              {...form.getInputProps("postalCode")}
              required
              disabled={isPending}
              data-testid="member-create-postal-code"
            />
          </AnimatedHeightWrapper>
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 6 }}>
          <AnimatedHeightWrapper>
            <PhoneInput
              inputComponent={StandardTextInput}
              // TODO: allow different countries lol. default country select was weird in UI
              country={PHONE_NUM_COUNTRY_CODE}
              international
              withCountryCallingCode
              {...form.getInputProps("phoneNumber")}
              label={t("memberCreatePage.fields.phoneNumber")}
              required
              disabled={isPending}
              data-testid="member-create-phonenum"
            />
          </AnimatedHeightWrapper>
        </Grid.Col>

        <Grid.Col span={12} pt="lg">
          <AnimatedHeightWrapper>
            <Select
              data-testid="member-create-language-pref"
              {...form.getInputProps("preferredLanguage")}
              disabled={isPending}
              rightSection={
                <IconChevronDown
                  strokeWidth={2.4}
                  style={{ height: 30, width: 30 }}
                />
              }
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: rem(10),
              }}
              styles={{
                root: {
                  border: `1px solid ${colors.gray20}`,
                  borderRadius: "15px",
                  paddingBlock: rem(25),
                  paddingRight: rem(25),
                  paddingLeft: rem(18),
                },
                wrapper: {
                  flex: 1,
                },
                label: {
                  fontSize: "12px",
                },
                input: {
                  borderColor: colors.primary,
                  height: 53,
                  fontWeight: 700,
                  borderRadius: "10px",
                  paddingLeft: "19px",
                },
                section: {
                  color: colors.primary,
                  marginRight: "12px",
                  width: "30px",
                },
              }}
              label={t("memberCreatePage.fields.preferredLanguage")}
              data={languageOptions}
              defaultValue={DEFAULT_LANGUAGE_OPTION}
            />
          </AnimatedHeightWrapper>
        </Grid.Col>
      </Grid>

      <Button
        style={{ marginTop: rem(32) }}
        type="submit"
        loading={isPending}
        data-testid="member-create-submit"
      >
        {t("memberCreatePage.submitButton")}
      </Button>
    </form>
  );
};
