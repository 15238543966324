import { Text12Medium } from "@components/Typography";
import { Divider, Box, Table, rem, Button, TableData } from "@mantine/core";
import { useColors } from "@utils/useColors";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { backendApi } from "src/backendApi/backendApi";
import { staticLinks } from "@config/staticLinks";

import type { V3User } from "@typings/mightierApiTypes";

const useTableData = (user: V3User): TableData => {
  const { t } = useTranslation();

  return useMemo(() => {
    return {
      body: [
        [t("memberInfo.firstName"), user.first_name],
        [t("memberInfo.lastName"), user.last_name],
        [t("memberInfo.email"), user.email],
        [
          t("memberInfo.caseNumber"),
          user.TEMP_latest_enterprise_user_eligibility_external_id ??
            t("memberInfo.caseNumberNotFound"),
        ],
        [t("memberInfo.accountId"), user.account],
      ],
    };
  }, [t, user]);
};

type Props = {
  user: V3User;
};

export const MemberInfo: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const colors = useColors();
  const tableData = useTableData(user);

  const handlePasswordReset = async () => {
    try {
      await backendApi.requestUserPasswordReset({ email: user.email });
      alert(t("memberInfo.passwordResetLinkSent"));
    } catch (error) {
      console.error(error);
      alert(t("memberInfo.passwordResetLinkError"));
    }
  };

  const handleClickGetShipmentStatus = async () => {
    try {
      const response = await backendApi.listShipments(String(user.account));
      const shipments = response.data.results;

      if (shipments.length > 0) {
        // making assumption first in array is the correct shipment
        const trackingUrl = shipments[0]?.tracking_url;
        if (!trackingUrl) {
          alert(t("memberInfo.noTrackingUrl"));
          return;
        }
        window.open(trackingUrl, "_blank");
      } else {
        alert(t("memberInfo.noShipmentsFound"));
      }
    } catch (error) {
      console.error(error);
      alert(t("memberInfo.shipmentStatusError"));
    }
  };

  return (
    <Box pb="md">
      <Box
        style={{
          borderRadius: "17px",
          border: `1px solid ${colors.gray20}`,
          overflowX: "hidden",
        }}
      >
        <Table
          verticalSpacing="lg"
          data={tableData}
          styles={{
            td: {
              paddingInline: rem(19),
              fontWeight: 500,
              minWidth: "150px",
              overflowWrap: "anywhere",
              verticalAlign: "top",
            },
          }}
          borderColor={colors.gray20}
          withColumnBorders
        />
      </Box>

      <Button
        component="a"
        variant="outline"
        my={rem(33)}
        onClick={handleClickGetShipmentStatus}
      >
        {t("memberInfo.shipmentStatus")}
      </Button>

      <Divider />

      <Text12Medium my={rem(20)}>
        {t("memberInfo.havingTechIssueText")}
      </Text12Medium>
      <Button
        component="a"
        variant="outline"
        mt={rem(6)}
        href={staticLinks.createSupportTicketPage}
        target="_blank"
      >
        {t("memberInfo.createSupportTicket")}
      </Button>
      <Button
        component="a"
        variant="outline"
        mt={rem(28)}
        onClick={handlePasswordReset}
      >
        {t("memberInfo.sendPasswordResetLink")}
      </Button>
    </Box>
  );
};
