import axios, { type AxiosInstance, type AxiosResponse } from "axios";

import {
  V3UserInfo,
  JwtObtainPairResponse,
  JwtRefreshResponse,
  JwtRequestOtpResponse,
  PaginatedResponse,
  V3User,
  V3RequestPasswordResetPayload,
  V3RequestPasswordResetResponse,
  V2ListShipmentResponse,
} from "@typings/mightierApiTypes";
import type {
  GreenshieldCreateMightierAccountPayload,
  GreenshieldCreateMightierAccountResponse,
} from "@typings/enterpriseServerlessTypes";
import { router } from "@config/tanstack";

const IS_REMEMBERED_STORAGE_KEY = "_authIsRemembered";
const JWT_ACCESS_STORAGE_KEY = "_jwtAccess";
const JWT_REFRESH_STORAGE_KEY = "_jwtRefresh";

class BackendApi {
  private _axios: AxiosInstance;
  onForcedLogout: () => unknown = () => {};

  constructor() {
    this._axios = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    // modify every request to include JWT auth header.
    // NOTE: certain endpoints could be included if we want
    this._axios.interceptors.request.use((config) => {
      const tk = this._storageInterface.getItem(JWT_ACCESS_STORAGE_KEY);
      config.headers.setAuthorization(`Bearer ${tk}`);
      return config;
    });

    this._axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        // https://blog.theashishmaurya.me/handling-jwt-access-and-refresh-token-using-axios-in-react-app
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const refreshResp = await this.refreshJwt();
            const token = refreshResp.data.access;

            // Retry the original request with the new token
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          } catch (error) {
            console.error(error);
            this._onLogout();
          }
        }

        // if it still errors
        return Promise.reject(error);
      },
    );
  }

  checkTokens() {
    return Boolean(
      this._storageInterface.getItem(JWT_ACCESS_STORAGE_KEY) &&
        this._storageInterface.getItem(JWT_REFRESH_STORAGE_KEY),
    );
  }

  async jwtMfaRequestOtp(
    username: string,
    password: string,
  ): Promise<AxiosResponse<JwtRequestOtpResponse>> {
    return await axios.post("/api/v3/auth/jwt_mfa/request_otp/", {
      username,
      password,
    });
  }

  async jwtObtainPair(
    secret: string,
    code: string,
    isRememebered: boolean,
  ): Promise<AxiosResponse<JwtObtainPairResponse>> {
    const resp = await axios.post("/api/v3/auth/jwt_mfa/obtain_pair/", {
      secret,
      code,
    });

    // NOTE: need to set this before calling this._storageInterface lol
    // THIS always goes into local storage!
    localStorage.setItem(IS_REMEMBERED_STORAGE_KEY, String(isRememebered));

    // kinda pointless to cast but whatever
    const respData = resp.data as JwtObtainPairResponse;
    this._storageInterface.setItem(JWT_ACCESS_STORAGE_KEY, respData.access);
    this._storageInterface.setItem(JWT_REFRESH_STORAGE_KEY, respData.refresh);

    return resp;
  }

  async refreshJwt(): Promise<AxiosResponse<JwtRefreshResponse>> {
    const refreshToken = this._storageInterface.getItem(
      JWT_REFRESH_STORAGE_KEY,
    );
    if (refreshToken === null) {
      throw new Error("Cannot refresh JWT as there is no refresh token");
    }
    const resp = await axios.post("/api/v3/auth/jwt/refresh/", {
      refresh: refreshToken,
    });

    // kinda pointless to cast but whatever
    const respData = resp.data as JwtRefreshResponse;

    this._storageInterface.setItem(JWT_ACCESS_STORAGE_KEY, respData.access);

    return resp;
  }

  async verifyJwtAuthOrLogout(): Promise<void> {
    const refreshToken = this._storageInterface.getItem(
      JWT_REFRESH_STORAGE_KEY,
    );

    try {
      await this._axios.request({
        method: "POST",
        url: "/api/v3/auth/jwt/verify/",
        data: { token: refreshToken },
      });
    } catch (error) {
      this._onLogout();
      this.onForcedLogout();

      // re-throw the error so that consumer knows it failed
      throw error;
    }
  }

  async getUserInfo(): Promise<AxiosResponse<V3UserInfo>> {
    return this._axios.request({
      url: "/api/v3/user_info/",
    });
  }

  async getUsers(
    params: {
      email?: string;
      enterprise_eligibility_states__external_id?: string;
    } = {},
  ): Promise<AxiosResponse<PaginatedResponse<V3User>>> {
    return this._axios.request({
      url: "/api/v3/users/",
      params,
    });
  }

  async requestUserPasswordReset(
    data: V3RequestPasswordResetPayload,
  ): Promise<AxiosResponse<V3RequestPasswordResetResponse>> {
    return this._axios.request({
      url: "/api/v3/auth/password/request_reset/",
      method: "POST",
      data,
    });
  }

  async listShipments(
    accountId: string,
  ): Promise<AxiosResponse<V2ListShipmentResponse>> {
    return this._axios.request({
      url: `/api/v2/billing/account/${accountId}/shipments/`,
    });
  }

  async createGreenshieldMightierAccount(
    data: GreenshieldCreateMightierAccountPayload,
  ): Promise<AxiosResponse<GreenshieldCreateMightierAccountResponse>> {
    return this._axios.request({
      url: "/api/v1/greenshield/member",
      method: "POST",
      data,
    });
  }

  async logout(): Promise<void> {
    this._onLogout();
  }

  private get _storageInterface(): Storage {
    const isUserRemembered = localStorage.getItem(IS_REMEMBERED_STORAGE_KEY);
    return isUserRemembered === "true" ? localStorage : sessionStorage;
  }

  /**
   *
   */

  private _onLogout(): void {
    const keys = [JWT_ACCESS_STORAGE_KEY, JWT_REFRESH_STORAGE_KEY];
    keys.forEach((k) => {
      // just in case we're in a bad state, just clear EVERYTHING
      localStorage.removeItem(k);
      sessionStorage.removeItem(k);
    });

    localStorage.removeItem(IS_REMEMBERED_STORAGE_KEY);

    router.navigate({ to: "/login", replace: true });
  }
}

// singleton
export const backendApi = new BackendApi();
